import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { pageSizes, customerPicturePrefix, awsCdnPrefix, customerDefaultPicture } from '../../utility/constants';
import {formatSixDigitNumber, capitalizeFirstLetter, formatDate} from '../../services/CommonService';
import { toast } from 'react-toastify';
import {getAllCustomers, deleteCustomerById} from '../../services/CustomerService';
import Swal from 'sweetalert2'

export default function ManageCustomer() {
    const [customer, setCustomers] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          getAllCustomerData(userDetails.token);
        }
    },[]);

    const getAllCustomerData = async (token) => {
        setLoading(true);
        const customerData = await getAllCustomers(token);
        if(customerData.error){
            setCustomers([]);
        }else{
            let allCustomers = [];
            for(let i=0; i< customerData.data.length; i++){
                let customer = Object.assign(customerData.data[i]);
                if(customer.id !== 0){
                    customer['uid'] = "UID" + formatSixDigitNumber(customer.id);
                    customer['customer_logo_link'] = customer['logo_link'] !== undefined && customer['logo_link'] !== null ? awsCdnPrefix+customerPicturePrefix+customer['logo_link'] : customerDefaultPicture;
                    customer['subscription_start_date_formatted'] = formatDate(customer['subscription_start_date']);
                    customer['subscription_end_date_formatted'] = formatDate(customer['subscription_end_date']);

                    allCustomers.push(customer);
                }

                
            }
            setCustomers(allCustomers);
        }
        setLoading(false);
    };

    const [colDefs, setColDefs] = useState([
        {headerName: "Unique ID", field: "uid"},
        {headerName: 'Profile',
            cellRenderer: params => {
                const onClick = () => {
                    navigate('/superadmin/editcustomer');
                    
                };
                return (
                    <>
                        <div className='flex items-center justify-center h-full'>
                            <div className='tabl-prof-img w-8 h-8 rounded-lg bg-white flex items-center justify-center'>
                                <img onClick={onClick} className='cursor-pointer w-6 h-6 rounded-image' src={params.data.customer_logo_link} alt='user'/>
                            </div>
                        </div>
                    </>
                );
            },
            
        },
        {headerName: "Customer", field: "name"},
        {headerName: "Email ID", field: "owner_email" },
        {headerName: 'Status',
            valueGetter: (params) => {
                return params.data.status;
            },
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem('updateCustomer', JSON.stringify(params.data));
                    navigate('/superadmin/updatecustomer');
                };
                return (
                    <>
                        <div onClick={onClick} className='cursor-pointer flex items-center h-full'>
                            {params.data.customer_status === 'ACTIVE' ? (
                                <div className='active-status text-xs inter-500 leading-none bg-[#EBF9F1] text-[#1F9254] py-2 px-2 rounded-3xl'>Active</div>
                            ) : (
                                null
                            )}
                            {params.data.customer_status === 'SUSPENDED' ? (
                                <div className='suspend-status text-xs inter-500 leading-none bg-[#FEF2E5] text-[#CD6200] py-2 px-2 rounded-3xl'>Suspended</div>
                            ) : (
                                null
                            )}
                            {params.data.customer_status === 'CANCELLED' ? (
                                <div className='cancel-status text-xs inter-500 leading-none bg-[#FBE7E8] text-[#A30D11] py-2 px-2 rounded-3xl'>Cancelled</div>
                            ) : (
                                null
                            )}
                        </div>
                    </>
                );
            },
            sortable: true,
            filter: true
        },
        {headerName: "Subscription", field: "subscription_code" },
        {headerName: "Start Date", field: "subscription_start_date_formatted" },
        {headerName: "End Date", field: "subscription_end_date_formatted" },
        {headerName: "No Of Learners", field: "max_learners" },
        {headerName: "Type", field: "cust_type" },
        {headerName: 'Actions',
            cellRenderer: params => {
                const onClick = () => {
                    sessionStorage.setItem('updateCustomer', JSON.stringify(params.data));
                    navigate('/superadmin/updatecustomer');
                };
                
                const onClickDelete = async () => {
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            setLoading(true);
                            const userDtl = getUserDetails();
                            const usersData = await deleteCustomerById(userDtl.token, params.data.id);
                            if(!usersData.error){
                                toast.success("Customer Deleted Successfully!");
                                await getAllCustomerData(userDtl.token);
                            }else{
                                toast.error("Some error occurred!");
                            }
                            setLoading(false);
                        } 
                    });
                    
                };

                return (
                    <>
                        <div className='relative course-navigate-btns flex items-center justify-between h-full'>
                            <a onClick={onClick} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M11.083 4H4.08301C3.55257 4 3.04387 4.21071 2.66879 4.58579C2.29372 4.96086 2.08301 5.46957 2.08301 6V20C2.08301 20.5304 2.29372 21.0391 2.66879 21.4142C3.04387 21.7893 3.55257 22 4.08301 22H18.083C18.6134 22 19.1221 21.7893 19.4972 21.4142C19.8723 21.0391 20.083 20.5304 20.083 20V13" stroke="#624DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M18.583 2.49998C18.9808 2.10216 19.5204 1.87866 20.083 1.87866C20.6456 1.87866 21.1852 2.10216 21.583 2.49998C21.9808 2.89781 22.2043 3.43737 22.2043 3.99998C22.2043 4.56259 21.9808 5.10216 21.583 5.49998L12.083 15L8.08301 16L9.08301 12L18.583 2.49998Z" stroke="#624DE3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                            <a onClick={onClickDelete} className='cursor-pointer flex items-center justify-center transition duration-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                    <path d="M3.08301 6H5.08301H21.083" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M8.08301 6V4C8.08301 3.46957 8.29372 2.96086 8.66879 2.58579C9.04387 2.21071 9.55257 2 10.083 2H14.083C14.6134 2 15.1221 2.21071 15.4972 2.58579C15.8723 2.96086 16.083 3.46957 16.083 4V6M19.083 6V20C19.083 20.5304 18.8723 21.0391 18.4972 21.4142C18.1221 21.7893 17.6134 22 17.083 22H7.08301C6.55257 22 6.04387 21.7893 5.66879 21.4142C5.29372 21.0391 5.08301 20.5304 5.08301 20V6H19.083Z" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.083 11V17" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M14.083 11V17" stroke="#A30D11" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>
                        </div>
                    </>
                );
            },
            
        }
    ]);
    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            flex: 1,
            minWidth: 200,
            maxWidth: 300,
        };
    }, []);
    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='py-10'>
                    <h3 className='text-2xl text-secondary inter-700 lg:hidden mb-6 px-6'>Manage Customer</h3>
                    <div className='flex justify-end lg:mx-12 lg:px-0 px-6 pb-5 mb-6 border-b-2 border-[#D0D0D0]'>
                        <button onClick={() => navigate('/superadmin/createcustomer')} type="button" className='bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow w-full md:w-auto'>+ Add New Customer</button>
                    </div>
                    <div className='lg:px-12 px-6'>
                        <div className="ag-theme-quartz" style={{ width: '100%', height: 500 }}>
                            <AgGridReact
                                rowData={customer}
                                columnDefs={colDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={5}
                                paginationPageSizeSelector={pageSizes}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}