import React, { useState, useEffect} from 'react';
import '../../spinner.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { getEvaluationReport } from '../../services/EvaluationService';

export default function Evaluation() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [evaluation, setEvaluation] = useState({
        "avg_attendance": 0,
        "avg_po_score": 0,
        "no_student_more_than_90_per": 0,
        "no_student_less_than_20_per": 0,
        "most_consumed_course": "",
        "highest_assessment_course": ""
    });

    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
            getEvaluationReportData();
        }
    },[]);

    const getEvaluationReportData = async () => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            setLoading(true);
            const response = await getEvaluationReport(userDetails.token);
            if(!response.error){
                setEvaluation(response.data);
            }
            setLoading(false);
        }else{

        }
    };

    const learnerPerformanceReport = (type) => {
        sessionStorage.setItem('learnerReportType', type);
        navigate('/admin/learnerreport')
    };


    return (
        <div>
          {loading ? (
            <div className="spinner-container">
              <img src='images/loading.gif' alt='GIF' />
            </div>
            ) : (
                <>
                    <div className='pt-10 lg:px-12 px-6 pb-12'>
                        <h3 className='text-2xl text-secondary text-center inter-700 lg:hidden'>Evaluation</h3>
                        <div className='mt-5'>
                            <div className='cohort-boxes-wrap flex justify-between flex-wrap mb-2'>
                                <div className='w-full md:w-[30%] xl:w-[28%] mb-6'>
                                    <div className='evlutn-chrt-card h-full flex flex-col justify-between bg-[#1D548D] min-h-52 2xl:min-h-[230px] rounded-3xl py-8 lg:py-10 xl:py-14 px-6 lg:px-8 xl:px-8'>
                                        <div>
                                            <div className='evlutn-hdng-wrap mb-5'>
                                                <h3 className='text-[1.575rem] md:text-3xl inter-700 text-white'>COHORT REPORT</h3>
                                            </div>
                                            <div className='evlutn-attndce-wrap mb-3 md:mb-5'>
                                                <h4 className='text-2xl inner-600 text-white'>{Math.ceil(evaluation.avg_attendance)}%</h4>
                                                <p className='text-lg md:text-base lg:text-lg inner-300 text-white leading-6'>Avg. Attendance</p>
                                            </div>
                                            <div className='evlutn-post-wrap mb-5 md:mb-10'>
                                                <h4 className='text-2xl inner-600 text-white'>{Math.ceil(evaluation.avg_po_score)}%</h4>
                                                <p className='text-lg md:text-base lg:text-lg inner-300 text-white leading-6'>Avg. Post Learning Assessment Score</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <a onClick={() => navigate('/admin/cohortreport')} className='cursor-pointer course-btn-yellow bg-[#E76457] hover:bg-black flex items-center justify-center h-10 2xl:h-12 text-white inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'><span className='text-xl'>+ </span> View in Detail</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:w-[30%] xl:w-[28%] mb-6'>
                                    <div className='evlutn-chrt-card h-full flex flex-col justify-between bg-[#1D548D] min-h-52 2xl:min-h-[230px] rounded-3xl py-8 lg:py-10 xl:py-14 px-6 lg:px-8 xl:px-8'>
                                        <div>
                                            <div className='evlutn-hdng-wrap mb-5'>
                                                <h3 className='text-[1.575rem] md:text-3xl inter-700 text-white'>LEARNER REPORT</h3>
                                            </div>
                                            <div className='evlutn-attndce-wrap mb-3 md:mb-5'>
                                                <h4 className='text-2xl inner-600 text-white'>{evaluation.no_student_more_than_90_per} Students</h4>
                                                <p className='text-lg md:text-base lg:text-lg inner-300 text-white leading-6'>with 90% + Score</p>
                                            </div>
                                            <div className='evlutn-post-wrap mb-5 md:mb-10'>
                                                <h4 className='text-2xl inner-600 text-white'>{evaluation.no_student_less_than_20_per} Students</h4>
                                                <p className='text-lg md:text-base lg:text-lg inner-300 text-white leading-6'>with less than 20% Score</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <a onClick={() => navigate('/admin/learnerreport')} className='cursor-pointer course-btn-yellow bg-[#E76457] hover:bg-black flex items-center justify-center h-10 2xl:h-12 text-white inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'><span className='text-xl'>+ </span> View in Detail</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full md:w-[30%] xl:w-[28%] mb-6'>
                                    <div className='evlutn-chrt-card h-full flex flex-col justify-between bg-[#1D548D] min-h-52 2xl:min-h-[230px] rounded-3xl py-8 lg:py-10 xl:py-14 px-6 lg:px-8 xl:px-8'>
                                        <div>
                                            <div className='evlutn-hdng-wrap mb-5'>
                                                <h3 className='text-[1.575rem] md:text-3xl inter-700 text-white'>LEARNING REPORT</h3>
                                            </div>
                                            <div className='evlutn-attndce-wrap mb-3 md:mb-5'>
                                                <h4 className='text-2xl inner-600 text-white'>{evaluation.most_consumed_course}</h4>
                                                <p className='text-lg md:text-base lg:text-lg inner-300 text-white leading-6'>Top Consumed Technology</p>
                                            </div>
                                            <div className='evlutn-post-wrap mb-5 md:mb-10'>
                                                <h4 className='text-2xl inner-600 text-white'>{evaluation.highest_assessment_course}</h4>
                                                <p className='text-lg md:text-base lg:text-lg inner-300 text-white leading-6'>Highest Assessment Score</p>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <a onClick={() => navigate('/admin/learningreport')} className='cursor-pointer course-btn-yellow bg-[#E76457] hover:bg-black flex items-center justify-center h-10 2xl:h-12 text-white inter-600 text-base md:text-sm lg:text-base 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'><span className='text-xl'>+ </span> View in Detail</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='view-boxes-wrap flex justify-between flex-wrap'>
                                <div className='w-full md:w-[30%] xl:w-[28%] mb-6'>
                                    <a onClick={() => learnerPerformanceReport('high')} className='cursor-pointer evlutn-view-card flex flex-col justify-between bg-[#1D548D] min-h-72 2xl:min-h-[300px] rounded-3xl py-6 lg:py-8 xl:py-8 px-6 lg:px-8 xl:px-8'>
                                        <div className='view-hdng-wrap mb-4'>
                                            <p className='text-lg md:text-base xl:text-lg text-white inter-700'>VIEW</p>
                                            <h3 className='view-box-ttl text-4xl md:text-3xl xl:text-4xl inter-700 text-white'>Best Performing Students</h3>
                                        </div>
                                        {/* <div className='evlutn-attndce-wrap'>
                                            <p className='text-lg inner-300 text-white leading-6'>in</p>
                                            <h4 className='text-2xl inner-600 text-white'>8th Grade</h4>
                                        </div> */}
                                    </a>
                                </div>
                                <div className='w-full md:w-[30%] xl:w-[28%] mb-6'>
                                    <a onClick={() => learnerPerformanceReport('low')} className='cursor-pointer evlutn-view-card flex flex-col justify-between bg-[#1D548D] min-h-72 2xl:min-h-[300px] rounded-3xl py-6 lg:py-8 xl:py-8 px-6 lg:px-8 xl:px-8'>
                                        <div className='view-hdng-wrap mb-4'>
                                            <p className='text-lg text-white inter-700'>VIEW</p>
                                            <h3 className='view-box-ttl text-4xl md:text-3xl xl:text-4xl inter-700 text-white'>Low Performing Students</h3>
                                        </div>
                                        {/* <div className='evlutn-attndce-wrap'>
                                            <p className='text-lg inner-300 text-white leading-6'>in</p>
                                            <h4 className='text-2xl inner-600 text-white'>8th Grade</h4>
                                        </div> */}
                                    </a>
                                </div>
                                <div className='w-full md:w-[30%] xl:w-[28%] mb-6'>
                                    <a onClick={() => navigate('/admin/learningreport')} className='cursor-pointer evlutn-view-card flex flex-col justify-between bg-[#1D548D] min-h-72 2xl:min-h-[300px] rounded-3xl py-6 lg:py-8 xl:py-8 px-6 lg:px-8 xl:px-8'>
                                        <div className='view-hdng-wrap mb-5'>
                                            <p className='text-lg text-white inter-700'>VIEW</p>
                                            <h3 className='view-box-ttl text-4xl md:text-3xl xl:text-4xl inter-700 text-white'>Topics with Highest Learning Time</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>  
                </>
            )
          }
        </div>
    );
}