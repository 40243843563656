import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { addCohort, updateCohort, getCohortById } from '../../services/SuperAdminCohortService';
import { toast } from 'react-toastify';
import { getAllCustomers } from '../../services/CustomerService';
import Select from 'react-select';

export default function SuperAdminCreateCohort() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [edit, setEdit] = useState(false);
    const [cohort, setCohort] = useState({cohort_code: "", cohort_desc: "", passing_per: 70, status: 'ACTIVE', org_name: '', org_type: '', address: '', city: '', state: '', zip_code: '', contact_person: '', contact_number: ''});
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
          navigate('/login');
        }else{
          setUserDetails(userDetails);
          let cohortData = sessionStorage.getItem('cohortEdit') !== undefined ? JSON.parse(sessionStorage.getItem('cohortEdit')) : {};
          let selectedCust = null
          if(cohortData !== null && cohortData !== undefined && cohortData.cohort_id !== undefined){
            cohortData["id"] = cohortData["cohort_id"];
            getCohortByIdData(userDetails.token, cohortData["cohort_id"]);
            setSelectedCustomer(cohortData["customer_id"]);
            selectedCust = cohortData["customer_id"];
            setEdit(true);
            sessionStorage.removeItem('cohortEdit');
          }else{
            cohortData = {}
          }
          getAllCustomerData(userDetails.token, selectedCust);
        }
    },[]);

    const getAllCustomerData = async (token, selectedCust) => {
        setLoading(true);
        const customerData = await getAllCustomers(token);
        if(customerData.error){
            setCustomers([]);
        }else{
            let allCustomers = [];
            for(let i=0; i< customerData.data.length; i++){
                let customer = Object.assign(customerData.data[i]);
                if(customer.id !== 0){
                    customer['label'] = customer['name'];
                    customer['value'] = customer['id'];
                    allCustomers.push(customer);
                }
                
            }
            setCustomers(allCustomers);
        }
        setLoading(false);
    };

    const getCohortByIdData = async (token, cohort_id) => {
        let resp = await getCohortById(token, cohort_id);
        if(resp.error === false){
            setCohort(resp.data);
        }
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setCohort({
          ...cohort,
          [name]: value,
        });
    };

    const handleCustomerChange = async (value) => {
        setSelectedCustomer(value);
        setCohort({
            ...cohort,
            ["customer_id"]: value
        });
    };

    const addCohortData = async () => {
        setLoading(true);
        let resp = await addCohort(cohort, userDetails.token);
        if(resp.error){
            toast.error(resp.data);
        }else{
            toast.success(resp.data);
        }
        setLoading(false);
    };

    const updateCohortData = async () => {
        setLoading(true);
        let resp = await updateCohort(cohort, userDetails.token);
        if(resp.error){
            toast.error(resp.data);
        }else{
            toast.success(resp.data);
        }
        setLoading(false);
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 lg:px-12 py-6 px-6'>
                    <h3 className='text-2xl text-secondary inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>{!edit ? "Add New Cohort" : "Update Cohort"}</h3>
                    <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate('/superadmin/managecohort')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <form className='w-full lg:w-4/5'>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[65%] mb-5 md:mb-8'>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={customers}
                                    value={customers.find(option => option.value === selectedCustomer)}
                                    onChange={(values) => handleCustomerChange(values.value)}
                                />
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Cohort Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="cohort_desc" value={cohort.cohort_desc} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Cohort Code <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="cohort_code" value={cohort.cohort_code} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap'>
                            <div className='flex flex-wrap'>
                                <div className='customer-radio-sngl mr-5 md:mr-8 mb-5 md:mb-8'>
                                    <input type="radio" id="suspended" name="status" onChange={handleValueChange} value='SUSPENDED' checked={cohort.status === "SUSPENDED"} />
                                    <label htmlFor="suspended">Suspended</label>
                                </div>
                                <div className='customer-radio-sngl mr-5 md:mr-8 mb-5 md:mb-8'>
                                    <input type="radio" id="active" name="status" onChange={handleValueChange} value='ACTIVE' checked={cohort.status === "ACTIVE"} />
                                    <label htmlFor="active">Active</label>
                                </div>
                                <div className='customer-radio-sngl mr-5 md:mr-8 mb-5 md:mb-8'>
                                    <input type="radio" id="cancelled" name="status" onChange={handleValueChange} value='CANCELLED' checked={cohort.status === "CANCELLED"} />
                                    <label htmlFor="cancelled">Cancelled</label>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between flex-wrap w-full md:w-4/5'>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Passing Percentage <span className='text-[#f00]'>*</span></label>
                                <input type='number' name="passing_per" onChange={handleValueChange} value={cohort.passing_per} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Organization Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="org_name" value={cohort.org_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Type of Organization <span className='text-[#f00]'>*</span></label>
                                <select name="org_type" onChange={handleValueChange} value={cohort.org_type} className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2 2xl:py-5 px-6 2xl:px-10 transition duration-300'>
                                    <option value="">Select Organization</option>
                                    <option value="PROFIT">Profit</option>
                                    <option value="NON_PROFIT">Non-Profit</option>
                                </select>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Address <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="address" onChange={handleValueChange} value={cohort.address} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>City <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="city" onChange={handleValueChange} value={cohort.city} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>State <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="state" onChange={handleValueChange} value={cohort.state} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Zip Code <span className='text-[#f00]'>*</span></label>
                                <input type='number' name="zip_code" onChange={handleValueChange} value={cohort.zip_code} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Contact Person</label>
                                <input type='text' name="contact_person" onChange={handleValueChange} value={cohort.contact_person} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Contact Phone</label>
                                <input type='number' name="contact_number" onChange={handleValueChange} value={cohort.contact_number} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='flex items-center mt-6 2xl:mt-10'>
                            {edit ? (
                                <button onClick={updateCohortData} type="button" className='w-full md:w-1/2 md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Update Cohort</button>
                            ) : (
                                <button onClick={addCohortData} type="button" className='w-full md:w-1/2 md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Add Cohort</button>
                            )}
                        </div>
                    </form>

                </div>
            )}
        </div>
    )
}