import { API_URL } from "../utility/constants";
import Swal from 'sweetalert2';
import { frontendKey } from "../utility/constants";
import CryptoJS from 'crypto-js';


export async function forgotChangePassword(forgotPassword) {
    try{
        const response = await fetch(API_URL+`users/login/forgotChangePassword`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(forgotPassword)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
    
};

export async function login(loginDetails) {
    try{
        const response = await fetch(API_URL+`users/login/loginUser`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(loginDetails)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function generatePassword(forgotPasswordEmail) {
    try{
        const response = await fetch(API_URL+`users/useremail/generatePassword`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(forgotPasswordEmail)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export function getUserDetails (){
    let userDetails = {};
    const userDetailsEncrypted = localStorage.getItem('userDetails');
    if (userDetailsEncrypted && !userDetailsEncrypted.includes("token")) {
        const bytes = CryptoJS.AES.decrypt(userDetailsEncrypted, frontendKey);
        const userDetailsDecrypted = bytes.toString(CryptoJS.enc.Utf8);
        userDetails = JSON.parse(userDetailsDecrypted);
    }

    if(userDetails === undefined || userDetails.user === undefined || userDetails.user.token_ts === undefined){
        localStorage.removeItem('userDetails');
        return {};
    }
    else if(userDetails.token === undefined){
        localStorage.removeItem('userDetails');
        return {};
    }else{
        const timestamp = Date.now();
        const token_ts = userDetails.user.token_ts;
        if((timestamp - token_ts) > 14400000){
            localStorage.removeItem('userDetails');
                Swal.fire({
                    title: "Session is expired, please re-login to continue!",
                    showConfirmButton: true,
                    confirmButtonText: "OK",
                    icon: "info"
                });
            
            return {};
        }
       
        return userDetails;
    }
    
    
};

export async function logout(token) {
    try{
        const response = await fetch(API_URL+`users/login/logout`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        });
        clearStore();
        return await response.json();
    }catch(error) {
        clearStore();
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export function clearStore (){
    localStorage.removeItem("userDetails");
    sessionStorage.clear();
    //sessionStorage.removeItem("permissions");
};

export async function updateUserCol(data, token) {
    try{
        const response = await fetch(API_URL+`users/user/updateUserInfo`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
    
};

export async function getAllUsersData(token) {
    try{
        const response = await fetch(API_URL+"users/user/getAllUser", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllUserWithCohortData(token) {
    try{
        const response = await fetch(API_URL+"users/user/getAllUserWithCohort", {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addUser(token, data) {
    try{
        const response = await fetch(API_URL+`users/user/addUser`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateUser(token, data) {
    try{
        const response = await fetch(API_URL+`users/user/updateUser`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function deleteUser(data, token) {
    try{
        const response = await fetch(API_URL+`users/user/deleteUser?user_id=`+data.id, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function getCustomerAdminPermission(token, id) {
    try{
        const response = await fetch(API_URL+"users/user/getUserPermissions?user_id="+id, {
            headers: {
              'Authorization': 'Bearer ' + token,
              'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function addCustomerAdminPermission(token, data) {
    try{
        const response = await fetch(API_URL+`users/user/addPermissions`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function updateCustomerAdminPermission(token, data) {
    try{
        const response = await fetch(API_URL+`users/user/deletePermissions`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function inviteUserWithEmail(token, data) {
    try{
        const response = await fetch(API_URL+`users/useremail/inviteUser`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred! Please try again later."};
    }
};

export async function storeUserDetails(value) {
    const valueString = JSON.stringify(value);    
    const encryptedData = CryptoJS.AES.encrypt(valueString, frontendKey).toString();
    localStorage.setItem('userDetails', encryptedData);
};