import React, { useState, useEffect } from 'react'
import { getUserDetails, logout, clearStore } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserProfile } from '../../services/ProfileService';
import { awsCdnPrefix, userPicturePrefix, customerPicturePrefix } from '../../utility/constants';
import { permissionsStore } from '../../services/Stores';

export default function AdminHeader() {
  const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
  const [mobileHeaderClicked, setMobileHeaderClicked] = useState(false);
  const [customerLogoLink, setCustomerLogoLink] = useState(awsCdnPrefix+customerPicturePrefix+"0.png");
  // const [permissions, setPermissions] = useState({});
  const { permissions, setPermissions, clearPermissions } = permissionsStore();
  const navigate = useNavigate();
  useEffect(() => {
    const userDetails = getUserDetails();
    if(userDetails.token !== undefined){
      setUserDetails(userDetails);
      // const perms = sessionStorage.getItem("permissions") === null ? {} : JSON.parse(sessionStorage.getItem("permissions"));
      // setPermissions(perms);
      if(userDetails.user.pri_color !== undefined && userDetails.user.pri_color !== null && userDetails.user.pri_color !== ''){
        document.documentElement.style.setProperty('--primary-color', userDetails.user.pri_color);
      }
      if(userDetails.user.sec_color !== undefined && userDetails.user.sec_color !== null && userDetails.user.sec_color !== ''){
        document.documentElement.style.setProperty('--secondary-color', userDetails.user.sec_color);
      }
      if(userDetails.user.ter_color !== undefined && userDetails.user.ter_color !== null && userDetails.user.ter_color !== ''){
        document.documentElement.style.setProperty('--tertiary-color', userDetails.user.ter_color);
      }
      if(userDetails.user.customer_logo_link !== null && userDetails.user.customer_logo_link !== undefined){
        setCustomerLogoLink(awsCdnPrefix+customerPicturePrefix+userDetails.user.customer_logo_link);
      }
      if(userDetails.user.customer_logo_link !== null && userDetails.user.customer_logo_link !== undefined){
        setCustomerLogoLink(awsCdnPrefix+customerPicturePrefix+userDetails.user.customer_logo_link);
      }
    }
  },[]);
  const changeMobileHeader = () => {
    mobileHeaderClicked ? setMobileHeaderClicked(false) : setMobileHeaderClicked(true);
  };

  const logoutUser = async () => {
    const userDetails = getUserDetails();
    if(userDetails.token !== undefined){
        let resp = await logout(userDetails.token);
        if(resp.error===false){
          toast.success(resp.data);
          navigate('/login');
        }else{
          toast.error(resp.data);
        }
    }else{
        clearStore();
        navigate('/login');
        toast.success("Successfully Logged Out!");
    }
    
  };

  const routeUser = (route) => {
    setMobileHeaderClicked(false);
    navigate(route);
  };

  return (
    <div>
        {/* Desktop header start here*/}
        <div className='site-header lg:flex hidden justify-between items-center py-3 px-12 border-b border-[#F8881B]'>
            <div className="header-logo-left">
              <div className='header-logo-sngl'>
                <img className='h-12' src={customerLogoLink} alt='sqc logo'/>
              </div>
            </div>
            <div className='wlcm-txt'>
                <h2 className='pacifico-regular text-black text-3xl'>Welcome {userDetails.user.first_name}!</h2>
                {/* show this when comes to course detail page  start*/}
                <h2 className='inter-700 text-secondary text-3xl hidden'>Classic Quantum</h2>
                {/* show this when comes to course detail page  start end*/}
            </div>
            <div className='header-logos flex'>
              <div className='header-logo-sngl'>
                <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer h-8' src='../images/quantum-black.webp' alt='quantum logo'/>
              </div>
            </div>
        </div>
        {/* Desktop header end here*/}
        {/* Mobile Header start Here */}
        <div className='mob-site-header shadow-md overflow-hidden py-4 px-6 flex lg:hidden justify-between bg-center bg-no-repeat bg-cover bg-primary'>
          <div className='mob-site-logo'>
            <img onClick={() => window.open("https://quantumliteracy.org", '_blank')} className='cursor-pointer h-6' src='../images/qlite-logo-w.webp' alt='qlite logo'/>
          </div>
          <div className='mob-header-icons flex'>
            {/* <div className='mob-header-icon'>
              <svg className='w-7 h-7' xmlns="http://www.w3.org/2000/svg" width="54" height="67" viewBox="0 0 54 67" fill="none">
                <g filter="url(#filter0_d_177_654)">
                  <path d="M48 2H12.3C8.73 2 6 4.72432 6 8.2869C6 11.8495 8.73 14.5738 12.3 14.5738H48V56.4865" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M48 56.4867H12.3C8.73 56.4867 6 53.7599 6 50.1941V8.24341" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M48.0009 8.24341H12.2441" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M16.7832 14.4864V39.4594L23.0264 37.3783L29.2697 39.4594V14.4864" stroke="white" strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <filter id="filter0_d_177_654" x="0" y="0" width="54" height="66.4866" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_177_654"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_177_654" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </div>
            <div className='mob-header-icon ml-10'>
              <svg className='w-7 h-7' xmlns="http://www.w3.org/2000/svg" width="56" height="53" viewBox="0 0 56 53" fill="none">
                <g filter="url(#filter0_d_177_659)">
                  <path d="M37.75 30.75C41.2708 30.75 44.125 27.8119 44.125 24.1875C44.125 20.5631 41.2708 17.625 37.75 17.625C34.2292 17.625 31.375 20.5631 31.375 24.1875C31.375 27.8119 34.2292 30.75 37.75 30.75Z" fill="white"/>
                  <path d="M40.6007 29.04V43.32L37.6607 41.7334L34.7207 43.32V29.04" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13.375 11.2499H31.375" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M13.375 17.625H21.625" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M32.8462 35.25H7V3H49V35.25H42.5385" stroke="white" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <filter id="filter0_d_177_659" x="0.5" y="0.5" width="55" height="52.3201" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_177_659"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_177_659" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </div> */}
            <div className='dash-ham flex justify-center ml-10 transition-all duration-300 ease-in-out'>
              {/* Add "active" class in "mob-ham-icon" on click of hamburger */}
              <button onClick={() => changeMobileHeader()} type='button' className={mobileHeaderClicked? 'mob-ham-icon active' : 'mob-ham-icon'}>
                <span className='w-8 2xl:w-10 h-0.5 bg-white block mb-1.5 2xl:mb-2 transition-transform duration-300 ease-in-out'></span>
                <span className='w-6 ml-auto 2xl:w-7 h-0.5 bg-white block mb-1.5 2xl:mb-2 transition-transform duration-300 ease-in-out'></span>
                <span className='w-8 2xl:w-4 h-0.5 bg-white block transition-transform duration-300 ease-in-out'></span>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Header end Here */}
        {/* Mobile Menu start Here */}
        {/* Add "active" class in "mob-menu-wrap" on click of hamburger */}
        <div className={mobileHeaderClicked? 'mob-menu-wrap active shadow-md bg-[#6E3174] fixed left-0 w-full transition-transform duration-300 ease-in-out z-50' : 'mob-menu-wrap shadow-md bg-[#6E3174] fixed left-0 w-full transition-transform duration-300 ease-in-out z-50'}>
          <div className='p-10'>
            <ul className='mb-10'>
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/admin/dashboard')} className='text-white inter-400 text-xl'>Dashboard</a>
              </li>
              {permissions["P1"] !== undefined && permissions["P1"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/admin/manageadmin')} className='text-white inter-400 text-xl'>Manage Admin</a>
                  </li>
              ): (
                  null
              )}
              {permissions["P2"] !== undefined && permissions["P2"] === true ? (
                <li className='text-right mb-2'>
                  <a onClick={() => routeUser('/admin/managecohort')} className='text-white inter-400 text-xl'>Manage Cohort</a>
                </li>
              ): (
                  null
              )}

              {permissions["P3"] !== undefined && permissions["P3"] === true ? (
                <li className='text-right mb-2'>
                  <a onClick={() => routeUser('/admin/managelearner')} className='text-white inter-400 text-xl'>Manage Learners</a>
                </li>
              ): (
                  null
              )}

              {permissions["P4"]!== undefined && permissions["P4"] === true ? (
                <li className='text-right mb-2'>
                  <a onClick={() => routeUser('/admin/evaluation')} className='text-white inter-400 text-xl'>Evaluations</a>
                </li>
              ): (
                  null
              )}

              {permissions["P5"] !== undefined && permissions["P5"] === true ? (
                  <li className='text-right mb-2'>
                    <a onClick={() => routeUser('/admin/customizeportal')} className='text-white inter-400 text-xl'>Customize Portal</a>
                  </li>
              ): (
                  null
              )}
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/admin/ngss')} className='text-white inter-400 text-xl'>NGSS - Curriculum</a>
              </li>
            </ul>
            <ul>
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/admin/profile')} className='text-white inter-400 text-xl'>My Profile</a>
              </li>
              <li className='text-right mb-2'>
                <a onClick={() => routeUser('/admin/adminpasswordreset')} className='text-white inter-400 text-xl'>Change Password</a>
              </li>
              <li className='text-right mb-2'>
                <a onClick={logoutUser} className='text-white inter-400 text-xl'>Log Out</a>
              </li>
            </ul>
          </div>
        </div>
        {/* Mobile Menu end Here */}
    </div>
  );
}
